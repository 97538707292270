import { Col, Divider, Row, Typography } from "antd";
import {
  DownloadIcon,
  AddIcon,
  useIntl,
  BasicButton,
  useScreenOptions,
} from "@datwyler/mfe-shared-components";

const { Title } = Typography;

const Header = (props) => {
  const { fetchDataForExcel, setIsProfileDialogOpen } = props;
  const intl = useIntl();
  const { isMobileScreen } = useScreenOptions();

  return (
    <Row style={{ marginBottom: isMobileScreen ? "48px" : "24px" }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={12}>
        <Title level={2} style={{ marginTop: "0px" }}>
          {intl.formatMessage({ id: "menu_user" })}
        </Title>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={12}
        style={{
          justifyContent: isMobileScreen ? "left" : "right",
          marginTop: isMobileScreen ? "20px" : "0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {isMobileScreen && (
          <BasicButton
            type="primary"
            icon={<AddIcon width={"20px"} height={"20px"} />}
            onClick={() => setIsProfileDialogOpen(true)}
          >
            {intl.formatMessage({ id: "add_user" })}
          </BasicButton>
        )}
        <BasicButton
          style={{ marginRight: "16px", marginLeft: "16px" }}
          onClick={fetchDataForExcel}
          icon={<DownloadIcon width={"24px"} height={"24px"} />}
        >
          {intl.formatMessage({ id: "export" })}
        </BasicButton>
        {!isMobileScreen && (
          <BasicButton
            type="primary"
            icon={<AddIcon width={"20px"} height={"20px"} />}
            onClick={() => setIsProfileDialogOpen(true)}
          >
            {intl.formatMessage({ id: "add_user" })}
          </BasicButton>
        )}
      </Col>
      {isMobileScreen && (
        <Divider
          style={{
            width: "100%",
            position: "absolute",
            left: "0px",
            top: "125px",
          }}
        />
      )}
    </Row>
  );
};

export default Header;
