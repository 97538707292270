import { useContext, useEffect, useMemo, useState } from "react";
import {
  LogoutIcon,
  DarkModeContext,
  EditIcon,
  UserContext,
  useTenant,
  TenantInformation,
  ApiContext,
  redirectToLogout,
  openChangePassword,
  useUser,
  useLoadingGif,
  NotificationContext,
  useIntl,
  BasicButton,
} from "@datwyler/mfe-shared-components";
import ProfileDialog from "../components/Dialogs/ProfileDialog";
import SignOutDialog from "../components/Dialogs/SignOutDialog";
import {
  titleStyle,
  textStyle,
  editProfileButtonStyle,
  keyTextStyle,
} from "../utils/styles";

import { Typography, Row, Col, Button, Card, Switch, Space } from "antd";
const { Text, Title } = Typography;

const Profile = () => {
  const {
    isDarkModeAuto: existingDarkModeAuto,
    isDarkMode: existingDarkMode,
  }: any = useContext(DarkModeContext);
  const [isDarkModeAuto, setIsDarkModeAuto] = useState(existingDarkModeAuto);
  const [isDarkMode, setIsDarkMode] = useState(existingDarkMode);
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const { user, getUserProfile }: any = useContext(UserContext);
  const { fetchTenantById, fetchTenantByIdData, isfetchTenantByIdLoading } =
    useTenant();
  const { updateUser, isUpdateUserLoading, updateUserResponseData } = useUser();
  const { uri }: any = useContext(ApiContext);
  const { setIsLoading, LoadingGif } = useLoadingGif();
  const { openNotification }: any = useContext(NotificationContext);
  const intl = useIntl();

  useMemo(() => {
    if (user?.tenant?.id) {
      fetchTenantById({
        variables: { id: user.tenant.id },
      });
    }
  }, [user]);

  useEffect(() => {
    setIsLoading(isUpdateUserLoading || isfetchTenantByIdLoading || false);
  }, [isUpdateUserLoading, isfetchTenantByIdLoading]);

  useEffect(() => {
    if (updateUserResponseData?.updateUser?.user) {
      openNotification({
        type: "success",
        description: intl.formatMessage({ id: "update_user_profile_success" }),
        placement: "topRight",
      });
      getUserProfile();
      handleCloseProfileDialog();
    }
  }, [updateUserResponseData]);

  const handleChangeDarkModeAuto = (isAuto) => {
    setIsDarkModeAuto(isAuto);
    window.localStorage.setItem("darkMode", isDarkMode.toString());
    window.dispatchEvent(new Event("darkModeChanged"));
    window.localStorage.setItem("darkModeAuto", isAuto.toString());
    window.dispatchEvent(new Event("darkModeAutoChanged"));
  };

  const handleChangeDarkMode = (isDarkMode) => {
    setIsDarkMode(isDarkMode);
    window.localStorage.setItem("darkMode", isDarkMode.toString());
    window.dispatchEvent(new Event("darkModeChanged"));
  };

  const handleOpenSignOutModal = () => {
    setIsSignOutModalOpen(true);
  };

  const handleCloseSignOutModal = () => {
    setIsSignOutModalOpen(false);
  };

  const handleOpenProfileModal = () => {
    setIsProfileDialogOpen(true);
  };

  const handleCloseProfileDialog = () => {
    setIsProfileDialogOpen(false);
  };

  const signOut = () => {
    handleCloseSignOutModal();
    redirectToLogout(uri);
  };

  const handleChangePassword = () => {
    openChangePassword(uri, user?.email);
  };

  return (
    <>
      <LoadingGif />
      <SignOutDialog
        isSignOutModalOpen={isSignOutModalOpen}
        handleCloseSignOutModal={handleCloseSignOutModal}
        signOut={signOut}
      />
      <ProfileDialog
        isProfileDialogOpen={isProfileDialogOpen}
        handleCloseProfileDialog={handleCloseProfileDialog}
        isUpdateUser={true}
        user={user}
        isOwnProfile={true}
        updateUser={updateUser}
      />
      <Row style={{ marginBottom: "24px" }}>
        <Col xs={{ span: 24 }} xl={{ span: 12 }}>
          <Title level={2} style={{ marginTop: "0px" }}>
            {intl.formatMessage({ id: "my_profile" })}
          </Title>
        </Col>
        <Col xs={{ span: 0 }} xl={{ span: 12 }} style={{ textAlign: "right" }}>
          <BasicButton
            onClick={handleOpenSignOutModal}
            icon={<LogoutIcon width={"24px"} height={"24px"} />}
          >
            {intl.formatMessage({ id: "sign_out" })}
          </BasicButton>
        </Col>
      </Row>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            title={
              <Text style={titleStyle}>
                {intl.formatMessage({ id: "dark_mode" })}
              </Text>
            }
          >
            <Row gutter={[0, 56]}>
              <Col span={24}>
                <Space>
                  <Text style={textStyle}>
                    {intl.formatMessage({ id: "dark_mode_auto" })}
                  </Text>
                  <Switch
                    value={isDarkModeAuto}
                    onChange={handleChangeDarkModeAuto}
                  />
                </Space>
              </Col>
              {!isDarkModeAuto && (
                <Col span={24}>
                  <Space>
                    <Text style={textStyle}>
                      {intl.formatMessage({ id: "dark_mode" })}
                    </Text>
                    <Switch
                      value={isDarkMode}
                      onChange={handleChangeDarkMode}
                    />
                  </Space>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
        {user && (
          <Col span={24}>
            <Card
              title={
                <Text style={titleStyle}>
                  {intl.formatMessage({ id: "profile_info" })}
                </Text>
              }
              extra={
                <BasicButton
                  type="text"
                  style={editProfileButtonStyle}
                  icon={<EditIcon width={"20px"} height={"20px"} />}
                  onClick={handleOpenProfileModal}
                >
                  {intl.formatMessage({ id: "edit_profile" })}
                </BasicButton>
              }
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Row gutter={[0, 24]}>
                    <Col span={8}>
                      <Text style={keyTextStyle}>
                        {intl.formatMessage({ id: "first_name" })}
                      </Text>
                    </Col>
                    <Col span={16}>
                      <Text style={textStyle}>{user.firstName}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 24]}>
                    <Col span={8}>
                      <Text style={keyTextStyle}>
                        {intl.formatMessage({ id: "last_name" })}
                      </Text>
                    </Col>
                    <Col span={16}>
                      <Text style={textStyle}>{user.lastName}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 24]}>
                    <Col span={8}>
                      <Text style={keyTextStyle}>
                        {intl.formatMessage({ id: "email" })}
                      </Text>
                    </Col>
                    <Col span={16}>
                      <Text style={textStyle}>{user.email}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 24]}>
                    <Col span={8}>
                      <Text style={keyTextStyle}>
                        {intl.formatMessage({ id: "contact_number" })}
                      </Text>
                    </Col>
                    <Col span={16}>
                      <Text style={textStyle}>{user.contact}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 24]}>
                    <Col span={8}>
                      <Text style={keyTextStyle}>
                        {intl.formatMessage({ id: "role" })}
                      </Text>
                    </Col>
                    <Col span={16}>
                      <Text style={textStyle}>{user.role}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 24]}>
                    <Col span={8}>
                      <Text style={keyTextStyle}>
                        {intl.formatMessage({ id: "company" })}
                      </Text>
                    </Col>
                    <Col span={16}>
                      <Text style={textStyle}>{user.company}</Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {fetchTenantByIdData?.tenantById && (
          <Col span={24}>
            <TenantInformation tenantInfo={fetchTenantByIdData.tenantById} />
          </Col>
        )}
        <Col span={24}>
          <Card
            title={
              <Text style={titleStyle}>
                {intl.formatMessage({ id: "change_password" })}
              </Text>
            }
          >
            <Row gutter={[0, 56]}>
              <Col span={24}>
                <BasicButton onClick={handleChangePassword}>
                  {intl.formatMessage({ id: "change_password" })}
                </BasicButton>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={{ span: 24 }} xl={{ span: 0 }}>
          <BasicButton
            danger
            onClick={handleOpenSignOutModal}
            icon={<LogoutIcon width={"24px"} height={"24px"} />}
          >
            {intl.formatMessage({ id: "sign_out" })}
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
