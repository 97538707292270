import { useState } from "react";
import { Space } from "antd";
import DeactivateUserDialog from "../../Dialogs/DeactivateUserDialog";
import {
  DEACTIVATED,
  BasicButton,
  useIntl,
} from "@datwyler/mfe-shared-components";

const LowerComponent = (props) => {
  const { selected, updateUser } = props;
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  const batchDeactivate = async () => {
    await Promise.all(
      selected.map((user) => {
        const submitValues = {
          tenant: user.tenant,
          id: user.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          role: user?.role,
          status: DEACTIVATED,
        };

        updateUser({
          variables: { input: submitValues },
        });
      })
    ).then((allResponses) => {
      setIsOpen(false);
    });
  };

  return (
    <Space>
      <DeactivateUserDialog
        handleCancel={() => setIsOpen(false)}
        handleOk={batchDeactivate}
        isOpen={isOpen}
      />
      <BasicButton danger onClick={() => setIsOpen(true)}>
        {intl.formatMessage({ id: "deactivate" })}
      </BasicButton>
    </Space>
  );
};

export default LowerComponent;
