import { Checkbox, Col, Row, Tag, Typography } from "antd";
import {
  MoreButtonItemProps,
  BasicDataTable,
  MoreButton,
  useIntl,
  ACTIVE,
} from "@datwyler/mfe-shared-components";

type MoreButtonProps = typeof MoreButtonItemProps;

const { Text } = Typography;

const UsersTable = (props) => {
  const {
    data,
    pageNumber,
    rowsPerPage,
    setPageNumber,
    setRowsPerPage,
    selected,
    setSelected,
    setSelectedUserForUpdate,
    setSelectedUserForDeactivate,
    reactivateUser,
  } = props;

  const intl = useIntl();

  const onChangeRowsPerPage = (val) => {
    setRowsPerPage(parseInt(val));
    setPageNumber(1);
  };

  const onChangePage = (val) => {
    setPageNumber(val);
  };

  const onSelectAll = () => {
    if (selected.length === data?.users?.length) {
      setSelected([]);
    } else {
      setSelected(data.users);
    }
  };

  const onClickCheckbox = (record) => {
    const newSelected = JSON.parse(JSON.stringify(selected));
    const index = indexInSelected(record);

    if (index >= 0) {
      // if already selected
      newSelected.splice(index, 1);
    } else {
      newSelected.push(record);
    }

    setSelected(newSelected);
  };

  const indexInSelected = (record) => {
    return selected.findIndex((user) => user.id === record.id);
  };

  const columns = [
    {
      title: (
        <Checkbox
          onChange={onSelectAll}
          checked={selected.length === data?.users?.length}
        />
      ),
      key: "isChecked",
      render: (_, record) => (
        <Checkbox
          onChange={() => onClickCheckbox(record)}
          checked={indexInSelected(record) >= 0}
        />
      ),
      width: "40px",
    },
    {
      title: intl.formatMessage({ id: "first_name" }),
      dataIndex: "firstName",
      ellipsis: true,
      width: "112px",
    },
    {
      title: intl.formatMessage({ id: "last_name" }),
      dataIndex: "lastName",
      ellipsis: true,
      width: "112px",
    },
    {
      title: intl.formatMessage({ id: "id" }),
      dataIndex: "id",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "email",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      render: (_, { status }) => {
        const color = status === ACTIVE ? "green" : "red";

        return (
          <Tag color={color} key={status}>
            {intl.formatMessage({ id: status })}
          </Tag>
        );
      },
      width: "112px",
    },
    {
      title: intl.formatMessage({ id: "role" }),
      dataIndex: "role",
      render: (_, { role }) => <Text>{intl.formatMessage({ id: role })}</Text>,
      width: "180px",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "company" }),
      dataIndex: "company",
      ellipsis: true,
    },
    {
      dataIndex: "action",
      render: (_, record) => {
        return <MoreButton items={getMoreButtonItems(record)} />;
      },
      width: "56px",
    },
  ];

  const getMoreButtonItems = (record) => {
    const options: MoreButtonProps[] = [
      {
        label: intl.formatMessage({ id: "update_user" }),
        onClick: () => handleUpdateUser(record.id),
      },
    ];

    if (record.status === ACTIVE) {
      options.push({
        label: intl.formatMessage({ id: "deactivate" }),
        onClick: () => handleDeactivateUser(record.id),
        danger: true,
      });
    } else {
      options.push({
        label: intl.formatMessage({ id: "reactivate" }),
        onClick: () => handleReactivateUser(record.id),
      });
    }
    return options;
  };

  const handleUpdateUser = (id) => {
    const selectedUser = getUserById(id);
    setSelectedUserForUpdate(selectedUser);
  };

  const handleDeactivateUser = (id) => {
    const selectedUser = getUserById(id);
    setSelectedUserForDeactivate(selectedUser);
  };

  const handleReactivateUser = (id) => {
    const selectedUser = getUserById(id);
    reactivateUser(selectedUser);
  };

  const getUserById = (id) => {
    return data.users.find((user) => user.id === id);
  };

  const getTableData = () => {
    return (
      data?.users?.map((user) => {
        const userWithKey = JSON.parse(JSON.stringify(user));
        userWithKey.key = userWithKey.id;
        return userWithKey;
      }) || []
    );
  };

  return (
    <Row style={{ marginTop: "24px" }}>
      <Col span={24}>
        {data?.users?.length > 0 && (
          <BasicDataTable
            data={getTableData()}
            columns={columns}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            total={data?.page?.totalElements}
          />
        )}
      </Col>
    </Row>
  );
};

export default UsersTable;
