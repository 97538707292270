import {
  BasicModal,
  buttonStyle,
  useIntl,
} from "@datwyler/mfe-shared-components";
import { textStyle } from "../../../utils/styles";
import { Typography } from "antd";

const { Text } = Typography;

const SignOutDialog = (props) => {
  const { isSignOutModalOpen, handleCloseSignOutModal, signOut } = props;
  const intl = useIntl();

  return (
    <BasicModal
      title={intl.formatMessage({ id: "log_out" })}
      isOpen={isSignOutModalOpen}
      handleCancel={handleCloseSignOutModal}
      handleOk={signOut}
      cancelButtonProps={{
        style: buttonStyle,
      }}
      okText={intl.formatMessage({ id: "log_out" })}
      cancelText={intl.formatMessage({ id: "cancel" })}
      okButtonProps={{
        type: "primary",
        danger: true,
        style: {
          ...buttonStyle,
          fontSize: "16px",
        },
      }}
    >
      <Text style={textStyle}>
        {intl.formatMessage({ id: "confirm_logout" })}
      </Text>
    </BasicModal>
  );
};

export default SignOutDialog;
