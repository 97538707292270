import { Route, Routes } from "react-router-dom";
import Profile from "./container/Profile";
import User from "./container/User";
import {
  withPermission,
  ContentWrapper,
  PageNotFound,
} from "@datwyler/mfe-shared-components";

const UserComponent = () => (
  <ContentWrapper>
    <User />
  </ContentWrapper>
);
const ProtectedUser = withPermission("/user")(UserComponent);
const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/user" element={<ProtectedUser />} />
      <Route
        path="/user/profile"
        element={
          <ContentWrapper>
            <Profile />
          </ContentWrapper>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
