import { ConfigProvider } from "antd";
import Router from "./Router";
import { useContext, useEffect, useState } from "react";
import {
  UserProvider,
  DarkModeContext,
  TenantIdProvider,
} from "@datwyler/mfe-shared-components";
import { useLocation } from "react-router-dom";

const App = (props: any) => {
  const { locale, version } = props;
  const { theme } = useContext<any>(DarkModeContext);
  const location = useLocation();
  const [tenantId, setTenantId] = useState(null);

  useEffect(() => {
    if (location?.state?.tenantId) setTenantId(location.state.tenantId);
  }, [location]);

  return (
    <UserProvider>
      <ConfigProvider theme={theme}>
        <TenantIdProvider tenantId={tenantId}>
          <Router locale={locale} version={version} />
        </TenantIdProvider>
      </ConfigProvider>
    </UserProvider>
  );
};

export default App;
