export const editProfileButtonStyle = {
  color: "#0C8595",
};

export const textStyle = {
  fontSize: "16px",
  fontWeight: 400,
};

export const keyTextStyle = {
  color: "#8F9399",
  fontSize: "16px",
  fontWeight: 400,
};

export const titleStyle = {
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 500,
};
