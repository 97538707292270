import { Col, Row, Flex, Input } from "antd";
import {
  BasicSelectMultiple,
  ADMIN,
  OPERATOR,
  ACTIVE,
  DEACTIVATED,
  useIntl,
  BasicButton,
  BasicFilter,
  BasicSortBy,
  useScreenOptions,
} from "@datwyler/mfe-shared-components";
import { useState } from "react";

const Filters = (props) => {
  const {
    filters,
    setFilters,
    filterDefaults,
    order,
    setOrder,
    setPageNumber,
  } = props;
  const [isShowFiltersDialog, setIsShowFiltersDialog] = useState(false);
  const intl = useIntl();
  const { isMobileScreen } = useScreenOptions();

  let interimFilters = { ...filters };

  const handleChangeRole = (values) => {
    interimFilters.roles = values;
  };

  const handleChangeCompany = (e) => {
    interimFilters.company = e.target.value;
  };

  const handleChangeStatus = (values) => {
    interimFilters.statuses = values;
  };

  const handleApply = () => {
    setPageNumber(1);
    setFilters(interimFilters);
    setIsShowFiltersDialog(false);
  };

  const handleReset = () => {
    setPageNumber(1);
    setFilters(filterDefaults);
    interimFilters = { ...filterDefaults };
  };

  const roleSelectOptions = [
    {
      label: intl.formatMessage({ id: ADMIN }),
      value: ADMIN,
    },
    {
      label: intl.formatMessage({ id: OPERATOR }),
      value: OPERATOR,
    },
  ];

  const statusSelectOptions = [
    {
      label: intl.formatMessage({ id: ACTIVE }),
      value: ACTIVE,
    },
    {
      label: intl.formatMessage({ id: DEACTIVATED }),
      value: DEACTIVATED,
    },
  ];

  // For sorting
  const typeSelectOptions = [
    {
      label: intl.formatMessage({ id: "name" }),
      value: "name",
    },
    {
      label: intl.formatMessage({ id: "status" }),
      value: "status",
    },
    {
      label: intl.formatMessage({ id: "role" }),
      value: "role",
    },
  ];

  const FilterContent = () => (
    <Flex>
      <Row style={{ width: "100%" }} gutter={[0, 16]}>
        <Col span={24}>
          <BasicSelectMultiple
            defaultValue={interimFilters.roles}
            options={roleSelectOptions}
            placeholder={intl.formatMessage({ id: "role" })}
            onChange={handleChangeRole}
          />
        </Col>
        <Col span={24}>
          <Input
            size="large"
            defaultValue={interimFilters.company}
            onChange={handleChangeCompany}
            placeholder={intl.formatMessage({ id: "enter_company_name" })}
          />
        </Col>
        <Col span={24}>
          <BasicSelectMultiple
            defaultValue={interimFilters.statuses}
            options={statusSelectOptions}
            placeholder={intl.formatMessage({ id: "status" })}
            onChange={handleChangeStatus}
          />
        </Col>
        <Col span={24} style={{ textAlign: "right" }}>
          <BasicButton onClick={handleReset}>
            {intl.formatMessage({ id: "reset" })}
          </BasicButton>
          <BasicButton
            type={"primary"}
            style={{ marginLeft: "16px" }}
            onClick={handleApply}
          >
            {intl.formatMessage({ id: "apply" })}
          </BasicButton>
        </Col>
      </Row>
    </Flex>
  );

  return (
    <Row>
      <Col span={24} style={{ display: "flex", alignItems: "center" }}>
        <BasicFilter
          content={<FilterContent />}
          open={isShowFiltersDialog}
          setOpen={setIsShowFiltersDialog}
        />
        <BasicSortBy
          options={typeSelectOptions}
          value={order}
          onChange={setOrder}
          justify={isMobileScreen ? "end" : "start"}
        />
      </Col>
    </Row>
  );
};

export default Filters;
