import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import Breadcrumbs from "../components/User/Breadcrumbs";
import Header from "../components/User/Header";
import Filters from "../components/User/Filters";
import UsersTable from "../components/User/UsersTable";
import {
  useUser,
  useLoadingGif,
  TenantIdContext,
  ACTIVE,
  DEACTIVATED,
  exportToExcel,
  useIntl,
  NotificationContext,
  UserContext,
  useScreenOptions,
} from "@datwyler/mfe-shared-components";
import LowerComponent from "../components/User/LowerComponent";
import ProfileDialog from "../components/Dialogs/ProfileDialog";
import DeactivateUserDialog from "../components/Dialogs/DeactivateUserDialog";

const filterDefaults = {
  roles: [],
  statuses: [ACTIVE],
  company: null,
};

let isReactivate = false;

const User = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState(filterDefaults);
  const [order, setOrder] = useState("status");
  const [selected, setSelected] = useState([]);
  const [selectedUserForUpdate, setSelectedUserForUpdate] = useState(null);
  const [selectedUserForDeactivate, setSelectedUserForDeactivate] =
    useState(null);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [isDeactivateUserDialogOpen, setIsDeactivateUserDialogOpen] =
    useState(false);
  const { user }: any = useContext(UserContext); // logged in user

  const { tenantId }: any = useContext(TenantIdContext);
  const { openNotification }: any = useContext(NotificationContext);
  const intl = useIntl();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const { isMobileScreen } = useScreenOptions();

  const {
    isFetchUsersLoading,
    fetchUsersData,
    fetchUsers,
    addUser,
    isAddUserLoading,
    addUserResponseData,
    resetAddUserData,
    updateUser,
    isUpdateUserLoading,
    updateUserResponseData,
    resetUpdateUserData,
  } = useUser();

  const {
    isFetchUsersLoading: isFetchUsersForExportLoading,
    fetchUsersData: fetchUsersForExportData,
    fetchUsers: fetchUsersForExport,
  } = useUser();

  useEffect(() => {
    refetchUsers();
  }, [tenantId, pageNumber, rowsPerPage, filters, order]);

  useEffect(() => {
    setIsLoading(
      isFetchUsersLoading ||
        isAddUserLoading ||
        isFetchUsersForExportLoading ||
        isUpdateUserLoading ||
        false
    );
  }, [
    isFetchUsersLoading,
    isAddUserLoading,
    isFetchUsersForExportLoading,
    isUpdateUserLoading,
  ]);

  useEffect(() => {
    // if a user is selected for update, open profile dialog
    setIsProfileDialogOpen(!!selectedUserForUpdate);
  }, [selectedUserForUpdate]);

  useEffect(() => {
    // if a user is selected for deactivate, open deactivate dialog
    setIsDeactivateUserDialogOpen(!!selectedUserForDeactivate);
  }, [selectedUserForDeactivate]);

  useEffect(() => {
    if (addUserResponseData?.addUser?.user) {
      openNotification({
        type: "success",
        description: intl.formatMessage({ id: "new_user_added" }),
        placement: "topRight",
      });

      resetAddUserData();
      refetchUsers();
      setIsProfileDialogOpen(false);
    }
  }, [addUserResponseData]);

  useEffect(() => {
    if (updateUserResponseData?.updateUser?.user) {
      if (!!selectedUserForUpdate || isReactivate) {
        openNotification({
          type: "success",
          description: intl.formatMessage({
            id: "update_user_profile_success",
          }),
          placement: "topRight",
        });

        setSelectedUserForUpdate(null);
        if (isReactivate) isReactivate = false;
      } else {
        openNotification({
          type: "success",
          description: intl.formatMessage({ id: "user_deactivated_success" }),
          placement: "topRight",
        });

        if (selectedUserForDeactivate) setSelectedUserForDeactivate(null);
        else setSelected([]);
      }

      resetUpdateUserData();
      refetchUsers();
    }
  }, [updateUserResponseData]);

  const refetchUsers = () => {
    const filtersToSend = getFilters();
    const sort = getSort();

    fetchUsers({
      variables: {
        tenantId: tenantId,
        page: { number: pageNumber - 1, size: rowsPerPage }, //page in api start from 0
        sort: [sort],
        filter: filtersToSend,
      },
    });
  };

  useEffect(() => {
    // For export to excel
    if (fetchUsersForExportData?.users?.users) {
      exportToExcel(
        getExcelData(),
        intl.formatMessage({ id: "excel_filename_user" })
      );
    }
  }, [fetchUsersForExportData]);

  const getSort = () => {
    let sort = "";

    if (order) {
      switch (order) {
        case "name":
          sort = `firstName,asc`;
          break;
        case "status":
          sort = `status,asc`;
          break;
        case "role":
          sort = `role,asc`;
          break;
        default:
          sort = `status,asc`;
      }
    }

    return sort;
  };

  const getFilters = () => {
    const filtersToSend = [];

    if (filters.statuses.length > 0) {
      filtersToSend.push(
        filters.statuses.map((val) => `status:` + val).join("|")
      );
    }

    if (filters.roles.length > 0) {
      filtersToSend.push(filters.roles.map((val) => `role:` + val).join("|"));
    }

    if (filters.company) {
      filtersToSend.push(`company:${filters.company}`);
    }

    return filtersToSend;
  };

  const fetchDataForExcel = () => {
    const filtersToSend = getFilters();
    const sort = getSort();

    fetchUsersForExport({
      variables: {
        tenantId: tenantId,
        filter: filtersToSend,
        sort: [sort],
        page: { number: 0, size: 999999 },
      },
    });
  };

  const getExcelData = () => {
    const excelData = fetchUsersForExportData?.users?.users?.map((data) => {
      const row = {};

      row[intl.formatMessage({ id: "first_name" })] = data.firstName || "-";
      row[intl.formatMessage({ id: "last_name" })] = data.lastName || "-";
      row[intl.formatMessage({ id: "id" })] = data.id || "-";
      row[intl.formatMessage({ id: "email" })] = data.email || "-";
      row[intl.formatMessage({ id: "status" })] = data.status
        ? intl.formatMessage({ id: data.status })
        : "-";
      row[intl.formatMessage({ id: "role" })] = data.role
        ? intl.formatMessage({ id: data.role })
        : "-";
      row[intl.formatMessage({ id: "company" })] = data.company || "-";

      return row;
    });
    return excelData;
  };

  const handleCloseProfileDialog = () => {
    setSelectedUserForUpdate(null);
    setIsProfileDialogOpen(false);
  };

  const handleDeactivateUser = () => {
    const submitValues = JSON.parse(JSON.stringify(selectedUserForDeactivate));
    submitValues.status = DEACTIVATED;

    updateUser({
      variables: { input: submitValues },
    });
  };

  const handleCancelDeactivate = () => {
    setSelectedUserForDeactivate(null);
  };

  const reactivateUser = (user) => {
    isReactivate = true;
    const submitValues = JSON.parse(JSON.stringify(user));
    submitValues.status = ACTIVE;

    updateUser({
      variables: { input: submitValues },
    });
  };

  return (
    <Row style={{ marginBottom: "24px" }} gutter={[0, 24]}>
      <LoadingGif />
      <ProfileDialog
        isProfileDialogOpen={isProfileDialogOpen}
        handleCloseProfileDialog={handleCloseProfileDialog}
        user={selectedUserForUpdate}
        isUpdateUser={!!selectedUserForUpdate}
        addUser={addUser}
        updateUser={updateUser}
        isOwnProfile={selectedUserForUpdate?.id === user.id}
      />
      <DeactivateUserDialog
        handleOk={handleDeactivateUser}
        handleCancel={handleCancelDeactivate}
        isOpen={isDeactivateUserDialogOpen}
      />
      {!isMobileScreen && (
        <Col span={24}>
          <Breadcrumbs />
        </Col>
      )}
      <Col span={24}>
        <Card>
          <Header
            fetchDataForExcel={fetchDataForExcel}
            setIsProfileDialogOpen={setIsProfileDialogOpen}
          />
          {selected.length > 0 ? (
            <LowerComponent selected={selected} updateUser={updateUser} />
          ) : (
            <Filters
              filters={filters}
              setFilters={setFilters}
              filterDefaults={filterDefaults}
              order={order}
              setOrder={setOrder}
              setPageNumber={setPageNumber}
            />
          )}

          <UsersTable
            data={fetchUsersData?.users}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            setPageNumber={setPageNumber}
            setRowsPerPage={setRowsPerPage}
            selected={selected}
            setSelected={setSelected}
            setSelectedUserForUpdate={setSelectedUserForUpdate}
            setSelectedUserForDeactivate={setSelectedUserForDeactivate}
            reactivateUser={reactivateUser}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default User;
